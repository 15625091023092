/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Zustand hook
 */
import {create} from 'zustand';
import {createJSONStorage, persist, StateStorage} from 'zustand/middleware';
import {InitData} from '../../api/queries/init';

const DONT_PERSIST: string[] = ['airlines', 'urlBase', 'init'];

type State = {
  airlines: string[];
  urlBase: string;
  init: InitData;
};

type Actions = {
  setAirlines: (airlines: string[]) => void;
  setInit: (urlBase: string, init: InitData) => void;
  reset: () => void;
};

const initialData: State = {
  airlines: [],
  urlBase: '',
  init: {
    isAdmin: false,
    isDeployer: false,
    isInternal: false,
    email: '',
    uid: '',
    approvalAirlineCodes: [],
    name: '',
    appConfigs: {
      dispatcherDisplay3ppIntakeViews: false,
      dispatcherDisplayApprovalsViews: false
    }
  }
};

export const customStorage: StateStorage = {
  getItem: async (name: string): Promise<string | null> => sessionStorage.getItem(name) ?? localStorage.getItem(name),
  setItem: async (name: string, value: string): Promise<void> => {
    sessionStorage.setItem(name, value);
    localStorage.setItem(name, value);
  },
  removeItem: async (name: string): Promise<void> => {
    sessionStorage.removeItem(name);
    localStorage.removeItem(name);
  }
};

const useBearStore = create<State & Actions>()(
  persist(
    set => ({
      ...initialData,
      setAirlines: (airlines: string[]) => set({airlines}),
      setInit: (urlBase: string, init: InitData) => set({urlBase, init}),
      reset: () => set(initialData)
    }),
    {
      name: 'dispatcher',
      storage: createJSONStorage(() => customStorage),
      partialize: state => Object.fromEntries(Object.entries(state).filter(([key]) => !DONT_PERSIST.includes(key)))
    }
  )
);

export default useBearStore;
