/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Button to submit a CPP deployment
 */

import React from 'react';
import {Box, Button, CircularProgress, Typography} from '@mui/material';
import useCppSubmitDeployment from '../../api/mutations/useCppSubmitDeployment';
import {strings} from '../../locale/strings';
import {spacing} from '@vst/beam';
import {Dispatcher} from '../../theme/Colors';
import {BannerDataProps} from './CppDetails';

type DeploymentButtonProps = {
  setBannerProps: (props: BannerDataProps) => void;
  id: string | undefined;
  selectedTails: string[];
  disabled: boolean;
};

export const CppDeploymentButton: React.FC<DeploymentButtonProps> = ({setBannerProps, id, selectedTails, disabled}) => {
  const {mutate, isLoading} = useCppSubmitDeployment(
    async () => {
      setBannerProps({
        show: true,
        message: strings.CppIntakeRequestDetailsSection.DeploymentRequest.success,
        status: 'success'
      });
    },
    async () => {
      setBannerProps({
        show: true,
        message: strings.CppIntakeRequestDetailsSection.DeploymentRequest.error,
        status: 'error'
      });
    }
  );

  const handleClick = () => {
    if (!id) {
      return;
    }
    mutate({
      id: id,
      selectedTails
    });
  };

  return isLoading ? (
    <Box
      mt={3}
      sx={{
        display: 'flex',
        flexFlow: 'row nowrap',
        gap: spacing[16],
        alignItems: 'center',
        mt: spacing[16],
        ml: spacing[72]
      }}
    >
      <CircularProgress color="primary" size={32} thickness={4} />
      <Typography variant="subtitle1" color={Dispatcher.AccessibleGray}>
        Attaching...
      </Typography>
    </Box>
  ) : (
    <Box mt={3}>
      <Button size="large" variant="contained" onClick={handleClick} disabled={disabled}>
        Request Deployment to Selected Aircraft
      </Button>
    </Box>
  );
};

export default CppDeploymentButton;
