/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Mutation query to get to submit a CPP deployment
 */

import {useMutation} from '@tanstack/react-query';
import {fetcher} from '../../utils/utils';

interface CppDeploymentSubmissionData {
  id: string;
  selectedTails: string[];
}

const useCppSubmitDeployment = (handleSuccess: () => Promise<void>, handleError: (error: string) => void) => {
  return useMutation({
    mutationFn: (data: CppDeploymentSubmissionData) =>
      fetcher({
        route: `cpp/submitDeployment`,
        params: data
      }),
    onSuccess: handleSuccess,
    onError: handleError
  });
};

export default useCppSubmitDeployment;
