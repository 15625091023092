/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Aircraft selector component
 */
import MultiSelectorDropdown from './MultiSelectorDropdown';
import {TailListLink} from './TailListLink';
import React from 'react';
import {strings} from '../locale/strings';
import {SxProps, Theme} from '@mui/material';

export const AircraftSelector: React.FC<{
  applicableTailIds: Set<string>;
  label: string;
  selectAllLabel: string;
  prompt: string;
  helperTextSx?: SxProps<Theme> | undefined;
  helperText?: React.ReactNode;
  tailListText?: string;
  fullWidth?: boolean;
  selectedAircraft: Set<string>;
  setSelectedAircraft: (selectedAircraft: Set<string>) => void;
}> = ({
  applicableTailIds,
  label,
  prompt,
  selectAllLabel,
  helperText,
  helperTextSx,
  tailListText,
  fullWidth,
  selectedAircraft,
  setSelectedAircraft
}) => {
  return (
    <MultiSelectorDropdown
      selectAllByDefault={false}
      fullWidth={fullWidth}
      items={applicableTailIds}
      setSelectedItems={setSelectedAircraft}
      label={label}
      prompt={selectedAircraft.size > 1 ? strings.NTails.replace('%n%', selectedAircraft.size.toString()) : prompt}
      selectAllLabel={selectAllLabel}
      renderFooter={selectedAircraft => <TailListLink tails={Array.from(selectedAircraft)} text={tailListText} />}
      selectedItems={selectedAircraft}
      helperTextSx={helperTextSx}
      helperText={helperText}
    />
  );
};
