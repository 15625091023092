/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Cpp Details Page
 */
import styled from '@emotion/styled';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {ShareLink} from '@viasat/insights-components';
import {colors, spacing} from '@vst/beam';
import {Accordion, AccordionDetails, AccordionSummary, Box, Button, SxProps, Theme, Typography} from '@mui/material';
import {ExpandMore as ExpandMoreIcon, Delete as DeleteIcon, ContentCopy} from '@mui/icons-material';
import CustomButton from '../../components/CustomButton';
import {v4 as uuidv4} from 'uuid';
import {spaComs} from '@viasat/insights-spa-package';

import BackButton from '../../components/BackButton';
import CppInformationTable from '../../components/CppDetailsTable';
import ReviewIntakeDetails from '../../components/CppIntakeRequestReview';
import ErrorComponent from '../../components/ErrorComponent';
import {Section} from '../../components/Section';
import {ModalDialog} from '../../components/ModalDialog';

import {PATHS} from '../../paths';
import PageContainerTemplate from '../../theme/PageContainerTemplate';

import {getCurrentUrlPath} from '../../utils/linkUtils';
import useBearStore from '../../utils/stores/useBearStore';
import {useCppRequestDraftStore} from '../../utils/stores/cppRequestDraftStore';
import {mapDraftToFetchedFormat, mapFetchedToDraftFormat} from '../../utils/cppUtils';

import {strings} from '../../locale/strings';
import qs from 'qs';
import {statusMap} from '../../components/CppRequestHistoryTable';
import {convertToFinalType} from '../Cpp/CppRequestSteps/CppRequestShared';
import {CppRequest, CppRequestDraft} from '../Cpp/types';
import CppHelmValues from './CppHelmValues';
import {Alert} from '../../components/Alert';
import CppDeploymentButton from './CppDeploymentButton';
import {useCppRequestDetails} from '../../api/queries/useCppRequestDetails';
import {AircraftSelector} from '../../components/AircraftSelector';
import {useAirlineTails} from '../../api/queries/useAirlineTails';
import TailList from '../../components/TailList';

const ACCORDION_DEFAULT_HEIGHT = '104px';
const AircraftSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[32]};
  width: 625px;
  align-items: flex-start;
  align-self: stretch;
  flex-grow: 0;
  padding-bottom: ${spacing[24]};
`;

const AccordionParentContainer = styled.div`
  display: flex;
  padding: 0 ${spacing[32]};
  flex-direction: column;
`;
const CppDetailsAccordion: React.FC<{
  children: JSX.Element[];
  expanded: boolean;
  onChange: () => void;
  dataTestId: string;
  sx?: SxProps<Theme>;
}> = ({children, expanded, onChange, dataTestId, sx}) => (
  <Accordion
    square={true}
    elevation={0}
    expanded={expanded}
    onChange={onChange}
    data-testid={dataTestId}
    sx={{
      borderBottom: '1px solid rgba(70, 89, 103, 0.5)',
      pb: spacing[4],
      ...sx
    }}
  >
    {children}
  </Accordion>
);
const CppDetailsAccordionSummary: React.FC<{
  children?: JSX.Element;
  title: string;
  bodyText: string;
  sx?: SxProps<Theme>;
}> = ({children, title, bodyText, sx}) => {
  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon sx={{p: spacing[12], fontSize: 24, fontWeight: 'bold'}} />}
      sx={{p: 0, '&:hover': {backgroundColor: colors.gray[100]}, ...sx}}
    >
      <Section title={title} bodyText={bodyText} layout="vertical" />
      {children}
    </AccordionSummary>
  );
};

export type BannerDataProps = {
  show: boolean;
  message: string;
  status: 'error' | 'success';
};

const Banner: React.FC<
  BannerDataProps & {
    onClickClose: () => void;
  }
> = ({show, message, status, onClickClose}) =>
  show ? (
    <Alert
      data-testid="cpp-details-banner"
      bodyText={message}
      status={status}
      closing={true}
      onClickClose={onClickClose}
    />
  ) : (
    <></>
  );

const CppIntakeDetails: React.FC = () => {
  // Use the useBearStore hook to get the airlines state
  const {airlines, isInternal, isDeployer} = useBearStore(state => ({
    airlines: state.airlines,
    isInternal: state.init.isInternal,
    isDeployer: state.init.isDeployer
  }));
  const navigate = useNavigate();
  const {drafts, deleteDraft, updateDraft} = useCppRequestDraftStore();

  const {id} = useParams<string>();
  const {refetch: refetchCppDetails, status, data} = useCppRequestDetails({id: id && drafts.has(id) ? undefined : id});

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<{title: string; message: string; okText: string}>({
    title: '',
    message: '',
    okText: ''
  });
  const [detailsExpanded, setDetailsExpanded] = useState<boolean>(false);
  const [helmValuesExpanded, setHelmValuesExpanded] = useState<boolean>(false);
  const [addAircraftExpanded, setAddAircraftExpanded] = useState<boolean>(true);
  const [bannerProps, setBannerProps] = useState<BannerDataProps>({show: false, message: '', status: 'success'});
  const [selectedAircraft, setSelectedAircraft] = useState(new Set<string>());

  const handleDuplicate = (cppRequestData: CppRequest) => {
    // Generate a new ID for the duplicate draft
    const newId = uuidv4();

    const duplicateData = {...cppRequestData};

    // Save the duplicate data as a draft
    updateDraft(newId, mapFetchedToDraftFormat(duplicateData));

    // Navigate to the beginning of the intake form with the new ID
    navigate(`${PATHS.cppRequestBase}/${newId}`);
  };

  // Try to get the CPP request data from the drafts using the id. If it doesn't exist in drafts, find it in the fetched data.
  const cppRequestData: CppRequest | undefined =
    id && drafts.has(id) ? mapDraftToFetchedFormat(id, drafts.get(id) as CppRequestDraft) : data;

  const urlPath = getCurrentUrlPath();
  const urlQuery = qs.stringify({}, {arrayFormat: 'comma'});
  const readableStatus = cppRequestData ? statusMap[cppRequestData.status] : undefined;
  const isDraft = cppRequestData?.status === 'draft';
  const isWaitingForHelm = cppRequestData?.status === 'submitted';
  const isReadyToDeploy = cppRequestData?.status === 'ready_to_deploy';
  const {data: airlineTails} = useAirlineTails(
    cppRequestData?.airlineCode,
    {
      onlyLabTails: cppRequestData?.labOnly || false
    },
    isReadyToDeploy && (isDeployer || isInternal) && Boolean(cppRequestData?.airlineCode)
  );
  // Expand the Helm and Values accordion if the request is in the 'submitted' status
  useEffect(() => setHelmValuesExpanded(true), [isWaitingForHelm]);

  // Swap airlines if the current airline is not in the list of available airlines
  useEffect(() => {
    if (!airlines || !data?.airlineCode) return;

    if (!airlines.includes(data.airlineCode)) {
      spaComs.sendGroupChange(data.airlineCode);
    }
  }, [data?.airlineCode, airlines]);

  return status === 'error' ? (
    <ErrorComponent
      headerError={strings.CppIntakeRequestDetailsSection.ThirdPartyApplicationDetails}
      bodyErrorMessage={strings.CppIntakeRequestDetailsSection.NoDataError}
      dataTestId="no-data-details-container"
    />
  ) : (
    <>
      <PageContainerTemplate
        title={cppRequestData?.application}
        subtitle={readableStatus}
        isSubtitleLoading={false}
        getFullElementId={(name: string, type: string) => `${name}-${type}`}
        disableSpaGroupSelector={true}
        preTitleStack={[
          <BackButton label={'Back'} key="backButton" onClick={() => navigate(PATHS.cppRequestHistory)} />
        ]}
        leftStack={[]}
        rightStack={[
          isDraft ? (
            <CustomButton
              getFullElementId={(name: string, type: string) => `cppDetailsDeleteButton__${name}-${type}`}
              open={openDeleteModal}
              onClick={() => {
                setOpenDeleteModal(true);
                setModalContent({
                  title: strings.CppIntakeRequestDetailsSection.DeleteRequest,
                  message: strings.CppIntakeRequestDetailsSection.ConfirmDelete,
                  okText: strings.CppIntakeRequestDetailsSection.DeleteRequest
                });
              }}
              IconComponent={DeleteIcon}
              buttonText="Delete"
            />
          ) : (
            <>
              <ShareLink
                key="shareLink"
                getFullElementId={(name: string, type?: string) => `update__${name}-${type}`}
                disabled={false}
                urlPath={urlPath}
                urlQuery={urlQuery}
              />
              <CustomButton
                getFullElementId={(name: string, type: string) => `cppDetailsDuplicateButton__${name}-${type}`}
                open={openDuplicateModal}
                onClick={() => {
                  setOpenDuplicateModal(true);
                  setModalContent({
                    title: strings.CppIntakeRequestDetailsSection.DuplicateTitle,
                    message: strings.CppIntakeRequestDetailsSection.DuplicateBody,
                    okText: strings.CppIntakeRequestDetailsSection.DuplicateRequest
                  });
                }}
                IconComponent={ContentCopy}
                buttonText="Duplicate"
              />
            </>
          )
        ]}
      >
        <Banner {...bannerProps} onClickClose={() => setBannerProps({show: false, message: '', status: 'success'})} />
        {cppRequestData && (
          <>
            <CppInformationTable data={cppRequestData} />
            <AccordionParentContainer>
              <CppDetailsAccordion
                expanded={detailsExpanded}
                onChange={() => setDetailsExpanded(value => !value)}
                dataTestId="accordion-summary"
              >
                <CppDetailsAccordionSummary
                  title={'Intake Request Form'}
                  bodyText={strings.CppIntakeRequestDetailsSection.ReviewDetails}
                  sx={{height: ACCORDION_DEFAULT_HEIGHT}}
                >
                  <Box sx={{mr: 8, display: 'flex', alignItems: 'center'}}>
                    {isDraft && (
                      <Button
                        size="medium"
                        variant="contained"
                        data-testid="continue-button-summary"
                        onClick={() => {
                          navigate(`${PATHS.cppRequestBase}/${id}`);
                        }}
                      >
                        Continue
                      </Button>
                    )}
                  </Box>
                </CppDetailsAccordionSummary>

                <AccordionDetails data-testid="accordion-details">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <ReviewIntakeDetails
                      data={convertToFinalType(cppRequestData ?? ({} as CppRequest))}
                      airline={cppRequestData.airlineName}
                    />
                  </Box>
                </AccordionDetails>
              </CppDetailsAccordion>
            </AccordionParentContainer>
            {(isWaitingForHelm || isReadyToDeploy) && isInternal && (
              <AccordionParentContainer>
                <CppDetailsAccordion
                  expanded={helmValuesExpanded}
                  onChange={() => setHelmValuesExpanded(value => !value)}
                  dataTestId="accordion-helm-values"
                >
                  <CppDetailsAccordionSummary
                    title={'Helm and Values Links'}
                    bodyText={strings.CppIntakeRequestDetailsSection.HelmDetails}
                    sx={{height: ACCORDION_DEFAULT_HEIGHT}}
                  ></CppDetailsAccordionSummary>

                  <AccordionDetails data-testid="accordion-helm-details">
                    <CppHelmValues
                      cppRequestData={cppRequestData}
                      isWaitingForHelm={isWaitingForHelm}
                      onSubmitSuccess={async () => {
                        await refetchCppDetails();
                        setBannerProps({
                          show: true,
                          message: strings.CppIntakeRequestDetailsSection.HelmUpdate.success,
                          status: 'success'
                        });
                      }}
                      onSubmitError={() =>
                        setBannerProps({
                          show: true,
                          message: strings.CppIntakeRequestDetailsSection.HelmUpdate.error,
                          status: 'error'
                        })
                      }
                      setBannerProps={setBannerProps}
                    />
                  </AccordionDetails>
                </CppDetailsAccordion>
              </AccordionParentContainer>
            )}
            {isReadyToDeploy && (isInternal || isDeployer) && (
              <AccordionParentContainer>
                <CppDetailsAccordion
                  expanded={addAircraftExpanded}
                  onChange={() => setAddAircraftExpanded(value => !value)}
                  dataTestId="accordion-add-cpp-tails"
                  sx={{p: `${spacing[24]} 0`}}
                >
                  <CppDetailsAccordionSummary
                    title={'Applicable Aircraft'}
                    bodyText={strings.CppIntakeRequestDetailsSection.ApplicableAircraftAccordionSubheading}
                  ></CppDetailsAccordionSummary>

                  <AccordionDetails sx={{pr: 0, pl: 0}} data-testid="accordion-add-cpp-tails-form">
                    <AircraftSelectorContainer>
                      <AircraftSelector
                        selectedAircraft={selectedAircraft}
                        setSelectedAircraft={setSelectedAircraft}
                        applicableTailIds={new Set(airlineTails)}
                        label={strings.Aircraft}
                        selectAllLabel={strings.AllAircraft}
                        prompt={strings.SelectAircraft}
                        tailListText={strings.AircraftStatusOnThisSelection}
                        fullWidth={true}
                      />
                    </AircraftSelectorContainer>
                    <Box sx={{pb: spacing[32]}}>
                      {selectedAircraft.size > 0 ? <TailList tails={Array.from(selectedAircraft)} /> : null}
                    </Box>
                  </AccordionDetails>
                </CppDetailsAccordion>
                <CppDeploymentButton
                  setBannerProps={setBannerProps}
                  id={cppRequestData.id}
                  selectedTails={Array.from(selectedAircraft)}
                  data-testid="submit-deployment-button"
                  disabled={selectedAircraft.size === 0}
                />
              </AccordionParentContainer>
            )}
          </>
        )}
      </PageContainerTemplate>
      <ModalDialog
        title={modalContent.title}
        displayState="default"
        okText={modalContent.okText}
        cancelText={strings.Cancel}
        open={openDeleteModal || openDuplicateModal}
        onOk={() => {
          if (openDeleteModal) {
            deleteDraft(id as string);
            navigate(PATHS.cppRequestHistory);
          } else if (openDuplicateModal) {
            handleDuplicate(cppRequestData as CppRequest);
          }
          setOpenDeleteModal(false);
          setOpenDuplicateModal(false);
        }}
        onClose={() => {
          setOpenDeleteModal(false);
          setOpenDuplicateModal(false);
        }}
      >
        <Typography variant="body1">{modalContent.message}</Typography>
      </ModalDialog>
    </>
  );
};

export default CppIntakeDetails;
