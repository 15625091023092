/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: CPP Helm Values component
 */
import React, {useCallback, useState} from 'react';
import {Box, Button, CircularProgress, Typography} from '@mui/material';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {spacing} from '@vst/beam';
import {FormInput} from '../../components/FormInput';
import {CppHelmValuesFormSchema, CppHelmValuesFormType, CppRequest} from '../Cpp/types';
import {FormSelect} from '../../components/FormSelect';
import useCppUpdateHelm from '../../api/mutations/useCppUpdateHelm';
import {Dispatcher} from '../../theme/Colors';
import {strings} from '../../locale/strings';
import {useGetMudApps} from '../../api/queries/useGetMudApps';
import {BannerDataProps} from './CppDetails';

/**
 * ReviewItem component displays a title and a value.
 *
 * @component
 * @param {string} title - The title to be displayed.
 * @param {string} value - The value to be displayed.
 * @returns {ReactElement} The rendered ReviewItem component.
 */
export const ReviewItem: React.FC<{title: string; value: string}> = ({title, value}) => (
  <Box sx={{display: 'flex', flexFlow: 'column nowrap'}}>
    <Typography variant="body2">{title}</Typography>
    <Typography variant="body1">{value}</Typography>
  </Box>
);

export const CppHelmValues: React.FC<{
  cppRequestData: CppRequest;
  onSubmitSuccess: () => Promise<void>;
  onSubmitError: () => void;
  isWaitingForHelm: boolean;
  setBannerProps: (props: BannerDataProps) => void;
}> = ({cppRequestData, onSubmitError, onSubmitSuccess, isWaitingForHelm, setBannerProps}) => {
  const [isAttaching, setIsAttaching] = useState<boolean>(false);
  const {data = [], error} = useGetMudApps(isWaitingForHelm, () => {
    setBannerProps({show: true, message: strings.CppIntakeRequestDetailsSection.mudError, status: 'error'});
  });

  const appNameOptions: Record<string, string> = !error
    ? Object.fromEntries(data.map((applicationName: string) => [applicationName, applicationName]))
    : {};

  const formMethods = useForm<CppHelmValuesFormType>({
    defaultValues: undefined,
    resolver: yupResolver(CppHelmValuesFormSchema)
  });

  const hasHelmData = cppRequestData.mudAppName && cppRequestData.helmLink && cppRequestData.valuesLink;
  const helmUpdateMutation = useCppUpdateHelm(
    async () => {
      formMethods.reset({mudAppName: '', helmLink: '', valuesLink: ''});
      await onSubmitSuccess();
      setIsAttaching(false);
    },
    () => {
      onSubmitError();
      setIsAttaching(false);
    }
  );

  const submitHelmData: SubmitHandler<CppHelmValuesFormType> = useCallback(
    (data: CppHelmValuesFormType) => {
      setIsAttaching(true);
      helmUpdateMutation.mutate({...data, id: cppRequestData.id});
    },
    [cppRequestData.id, helmUpdateMutation]
  );

  return isAttaching ? (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'row nowrap',
        gap: spacing[16],
        alignItems: 'center',
        mt: spacing[16],
        ml: spacing[72]
      }}
    >
      <CircularProgress color="primary" size={32} thickness={4} />
      <Typography variant="subtitle1" color={Dispatcher.AccessibleGray}>
        Attaching...
      </Typography>
    </Box>
  ) : hasHelmData ? (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column nowrap',
        maxWidth: '600px',
        mt: spacing[4],
        ml: spacing[8],
        gap: spacing[16]
      }}
    >
      <ReviewItem title="App Name" value={cppRequestData.mudAppName} />
      <ReviewItem title="Helm Chart Link" value={cppRequestData.helmLink} />
      <ReviewItem title="Values File Link" value={cppRequestData.valuesLink} />
    </Box>
  ) : (
    <Box sx={{display: 'flex', flexFlow: 'column nowrap', maxWidth: '600px', mt: spacing[4], gap: spacing[16]}}>
      <Typography color={Dispatcher.AccessibleGray} variant="body1" sx={{mb: spacing[16]}}>
        {strings.CppIntakeRequestDetailsSection.HelmInputPrompt}
      </Typography>

      <FormProvider {...formMethods}>
        <FormSelect name="mudAppName" label="App Name" options={appNameOptions} required />
        <FormInput name="helmLink" label="Helm Chart Link" required />
        <FormInput name="valuesLink" label="Values File Link" required />
        <Box>
          <Button
            data-testid="StepNextButton"
            size="medium"
            variant="contained"
            onClick={formMethods.handleSubmit(submitHelmData)}
          >
            Continue
          </Button>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default CppHelmValues;
