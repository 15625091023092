/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: react-query hook to get MUD application names
 */

import {useQuery} from '@tanstack/react-query';
import {fetcher} from '../../utils/utils';

/**
 * custom hook that fetches MUD application names
 * @param isEnabled Boolean to enable or disable the query. Default is true
 * @param onError Callback function to handle any errors that occur during the fetch
 * @returns An object containing data and error from the query
 */
export const useGetMudApps = (isEnabled = true, onError = (error: Error) => {}) => {
  return useQuery(['mudApps'], () => fetcher({route: `mud/applications`, params: {}}), {
    staleTime: Infinity,
    enabled: isEnabled,
    onError: onError
  });
};
