/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Airline Tails Route
 */
import {useQuery} from '@tanstack/react-query';
import {fetcher} from '../../utils/utils';

export const useAirlineTails = (
  airlineCode: string | undefined,
  params: {onlyLabTails: boolean},
  enabled: boolean = Boolean(params)
) =>
  useQuery<string[]>({
    queryKey: ['airlineTails', airlineCode],
    queryFn: () => fetcher({route: `airlines/${airlineCode || ''}/tails`, params}),
    refetchOnWindowFocus: false,
    enabled,
    staleTime: Infinity,
    cacheTime: Infinity
  });
